declare global {
    interface Window {
        google_tag_manager?: {
            dataLayer?: {
                gtmLoad?: boolean;
            };
        };
    }
}

export const onGtmLoaded = (): Promise<void> => {
    const isServer = typeof window === 'undefined';

    const maxAmountOfTries = 10;
    const sleep = 250;
    let tries = 0;

    return new Promise<void>((resolve, reject): void => {
        const testGtmLoaded = (): void => {
            if (!isServer && window.google_tag_manager?.dataLayer?.gtmLoad) {
                return resolve();
            }
            if (tries === maxAmountOfTries || isServer) {
                return reject('Err, GTM is unavailable.');
            }
            tries++;
            setTimeout((): void => testGtmLoaded(), sleep);
        };
        testGtmLoaded();
    });
};
