import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import { SidepanelModule as SidePanelModuleProps } from '~/lib/data-contract';
import { Sidepanel, useTracking } from '~/shared/components';
import { useFrame } from '~/shared/utils';

type SidepanelModule = {
    sidepanelModule: SidePanelModuleProps;
    showSidePanel: boolean;
    handleCloseSidePanel: () => void;
    callToActionText?: string;
};

export const SidepanelModule = ({
    sidepanelModule,
    showSidePanel,
    handleCloseSidePanel,
    callToActionText,
}: SidepanelModule) => {
    const { data: frame } = useFrame();
    const { trackSidePanelOpen } = useTracking();

    const M40ContactForm = dynamic(() =>
        import('~/templates/blocks/components/M40ContactForm/M40ContactForm').then(
            (mod) => mod.M40ContactForm,
        ),
    );
    const M41NewsletterForm = dynamic(() =>
        import('~/templates/blocks/components/M41NewsletterForm/M41NewsletterForm').then(
            (mod) => mod.M41NewsletterForm,
        ),
    );
    const M100RichText = dynamic(() =>
        import('~/templates/blocks/components/M100RichText/M100RichText').then(
            (mod) => mod.M100RichText,
        ),
    );

    const Module = useMemo(() => {
        switch (sidepanelModule.type) {
            case 'm40ContactFormModule':
                if (showSidePanel) {
                    trackSidePanelOpen({
                        button_text: `${callToActionText}`,
                        form_type: 'm40ContactForm',
                    });
                }
                return (
                    <M40ContactForm
                        loadOnSidepanel
                        onPanelClose={handleCloseSidePanel}
                        {...sidepanelModule}
                    />
                );

            case 'm41NewsletterFormModule':
                if (showSidePanel) {
                    trackSidePanelOpen({
                        button_text: `${callToActionText}`,
                        form_type: 'm41NewsletterForm',
                    });
                }
                return (
                    <M41NewsletterForm
                        loadOnSidepanel
                        {...sidepanelModule}
                        onPanelClose={handleCloseSidePanel}
                    />
                );

            case 'm100RichTextModule':
                if (showSidePanel) {
                    trackSidePanelOpen({
                        button_text: `${callToActionText}`,
                        form_type: 'm100RichText',
                    });
                }
                return <M100RichText loadOnSidepanel {...sidepanelModule} />;

            default:
                return <></>;
        }
    }, [sidepanelModule, showSidePanel]);

    return (
        <Sidepanel
            culture={frame?.culture}
            open={showSidePanel}
            onClose={handleCloseSidePanel}
            withSSR
        >
            {Module}
        </Sidepanel>
    );
};
