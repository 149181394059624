import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { create } from 'zustand';
export type CookieConsentSettings = {
    marketing?: boolean;
    functional?: boolean;
    required?: boolean;
    statistics?: boolean;
};

export type MarketingParams = {
    // UTM
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
    utm_id?: string;

    // SoMe platforms
    gclid?: string;
    fbclid?: string;
    msclkid?: string;
    twclid?: string;

    // Generics
    referrer?: string;
    og_location?: string;
};

const marketingUrlParamKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_id',
    'gclid',
    'fbclid',
    'msclkid',
    'twclid',
];
export const marketingParamsKey = 'kom_marketing_params';
export type CookieConsentState = {
    setConsent: (settings: CookieConsentSettings) => void;
    consent: CookieConsentSettings;
    setInitialMarketingParams: () => void;
    temporaryMarketingParams: MarketingParams;
    transferMarketingParamsToCookie: () => void;
    getMarketingParams: () => MarketingParams;
    didSetTemporaryParams: boolean;
    addMarketingToPayload: <T>(payload: T) => T & MarketingParams;
};

const getMarketingParamsCookie = () => {
    const persistedMarketingParams = getCookie(marketingParamsKey) as string | undefined | null;
    if (!persistedMarketingParams) return;

    try {
        const parsedCookie: MarketingParams = JSON.parse(persistedMarketingParams);
        return parsedCookie;
    } catch (e) {
        return;
    }
};
const clearMarketingCookie = () => {
    deleteCookie(marketingParamsKey);
};

/**
 * Hook that provides cookie consent state of different kinds.
 *
 * Usage example:
 *
 *  const { constent } = useCookieConsent();
 *  ...
 *  { constnt.marketing && <ComponentThatRequiresMarketingConsent /> }
 */

export const useCookieConsent = create<CookieConsentState>((set, get) => ({
    setConsent: (settings) => {
        set(() => ({
            consent: settings,
        }));
        // Wait until next tick to transfer params, since params must read from consent state.
        setTimeout(() => {
            if (!settings.marketing) {
                clearMarketingCookie();
            } else {
                get().transferMarketingParamsToCookie();
            }
        });
    },
    consent: {},
    // Use this to store initial tracking params from URL. Will only set them once regardless of how many times it's called.
    setInitialMarketingParams: () => {
        if (get().didSetTemporaryParams) return;
        const url = new URL(window.location.href);
        const params = url.searchParams;
        const marketingParams: Record<string, string | undefined> = {};
        marketingUrlParamKeys.map((key) => {
            marketingParams[key] = params.get(key) ?? undefined;
        });
        marketingParams.referrer = document.referrer;
        marketingParams.og_location = window.location.href;

        set(() => ({
            didSetTemporaryParams: true,
            temporaryMarketingParams: marketingParams,
        }));
    },
    // This hold marketing params when page is loaded initially if no consent is given
    temporaryMarketingParams: {},
    // Track if we already set temporary params before
    didSetTemporaryParams: false,
    // This is intended to be called only once when consent is given
    transferMarketingParamsToCookie: () => {
        const hasMarketingConsent = get().consent.marketing;
        if (!hasMarketingConsent) return;
        const existingValue = getMarketingParamsCookie();
        // If value has already been set, no point in setting it again
        if (existingValue) {
            return false;
        }
        setCookie(marketingParamsKey, get().temporaryMarketingParams, {
            httpOnly: false,
            secure: true,
        });
    },
    getMarketingParams: () => {
        const persistedMarketingParams = getMarketingParamsCookie();
        const hasMarketingConsent = get().consent.marketing;
        const temporaryMarketingParams = get().temporaryMarketingParams;
        if (hasMarketingConsent) {
            return persistedMarketingParams ?? temporaryMarketingParams;
        }
        return temporaryMarketingParams;
    },
    addMarketingToPayload: (payload) => {
        const marketingParams = get().getMarketingParams();
        return {
            ...payload,
            ...marketingParams,
        };
    },
}));

/**
 * How to hook it up:
 *
 * Depends, on your cookie consent management platform.
 * Regardless, when consent is given, you take that, map it into an CookieConsentSettings object, and use the setConsent of the useCookieConsent hook.
 *
 * Example: if your provider is Cookiebot (https://www.cookiebot.com):
 * 1. Utilize the CookiebotOnConsentReady event(on window). (The event is triggered when the user's consent state is ready, either from being submitted or loaded from an existing cookie)
 * The event object returns a Cookiebot.consent object, where in  marketing, necessary, preferences and statistics are represented with boolean values.
 * 2. Take the event values, and set them in the zustand state with setConsent of the useCookieConsent hook.
 * 3. Have a coffee, maybe a cookie with it...
 */
